import axios from './authRequest';
import qs from 'qs';
export const CreatePartsByVehicleId = (parts, place) =>
	axios.post('/api/parts/by-vehicle-id', {partsData: parts, place: place});
export const createParts = parts => axios.post('/api/parts', parts);
export const createPartsAndRetrieve = parts => axios.post('/api/parts/retrieve', parts);

export const GetMainCategory = () => axios.get('/api/parts/main-category');
export const GetSubCategory = mainCategoryId => axios.get(`/api/parts/main-category/${mainCategoryId}/sub-category`);
export const GetExactCategory = subCategoryId => axios.get(`/api/parts/sub-category/${subCategoryId}/exact-category`);

export const GetPartsStatus = () => axios.get('/api/parts/dropdowns/status');
export const UpdatePartsStatus = newStatus => axios.put('/api/parts/dropdowns/status', newStatus);

export const PatchWarehouseAndSku = (ids, warehouseAndSku, cancelToken) =>
	axios.patch(`/api/parts/warehouse-and-sku`, {...warehouseAndSku, ids: ids}, {cancelToken: cancelToken});

export const patchWarehouseAndSkuV2 = (data, cancelToken) =>
	axios.patch(`/api/parts/v2/warehouse-and-sku`, data, {cancelToken: cancelToken});

export const PatchWarehouseAndSkuAndMarketplace = (ids, warehouseAndSku, cancelToken) =>
	axios.patch(
		`/api/parts/warehouse-and-sku-and-marketplace`,
		{...warehouseAndSku, ids: ids},
		{cancelToken: cancelToken}
	);

export const GetParts = url => axios.get(`/api${url}`);
export const GetAllExactCategories = () => axios.get('/api/parts/exact-categories');
export const GetAllMountingSides = () => axios.get('/api/parts/mounting-sides');
export const GetAllConditions = () => axios.get('/api/parts/conditions');
export const GetAllStatuses = () => axios.get('/api/parts/statuses');
export const getPartStatus = id => axios.get(`api/parts/status/${id}`);
export const getPartStatusMultiple = ids => axios.post(`api/parts/part-statuses`, ids);
export const getPartsByFilters = base64 => axios.get('/api/parts/manage-filters', {params: {base64: base64}});
export const getPartsByFiltersDb = base64 => axios.get('/api/parts/manage-filters/db', {params: {base64: base64}});
export const getPartsByIds = ids => axios.get('/api/parts/manage?ids=' + ids);
export const getPartForOrder = (id, isOriginalId) => axios.get(`/api/parts/for-order/${id}?original=${isOriginalId}`);
export const getPartsWithVehicleByIds = ids => axios.get('/api/parts/manage?ids=' + ids);
export const GetPartById = id => axios.get(`/api/parts/${id}`);
export const GetAnyPartById = id => axios.get(`/api/parts/any/${id}`);
export const GetPartByProductId = id => axios.get(`api/parts/by-product-id/${id}`);
export const updateMainData = part => axios.put(`/api/parts/main-data`, part);
export const DuplicatePart = partId => axios.post(`/api/parts/duplicate-part/${partId}`);
export const updateMainDataMultiple = (parts, cancelToken, isCategoryUpdate = false) =>
	axios.put(
		`/api/parts/main-data-multiple`,
		{
			isCategoryUpdate,
			parts,
		},
		{cancelToken: cancelToken}
	);
export const updateMountingSides = part => axios.put(`/api/parts/mounting-sides`, part);
export const updateMountingSidesMultiple = (parts, cancelToken) =>
	axios.put(`/api/parts/mounting-sides-multiple`, parts, {cancelToken: cancelToken});
export const updateReferences = part => axios.put(`/api/parts/references`, part);
export const updateReferencesMultiple = (parts, cancelToken) =>
	axios.put(`/api/parts/references-multiple`, parts, {cancelToken: cancelToken});
export const updateConditions = part => axios.put(`/api/parts/condition-descriptions`, part);
export const updateConditionsMultiple = (parts, cancelToken) =>
	axios.put(`/api/parts/condition-descriptions-multiple`, parts, {cancelToken: cancelToken});
export const getConditionStatus = partIds =>
	axios.get('/api/parts/condition-status', {params: {partIds: partIds.join(',')}});
export const updatePhotos = part => axios.put(`/api/parts/photos`, part);

export const restoreParts = partIds => axios.patch(`/api/parts/restore`, partIds);
export const restoreAllParts = urlParams => axios.patch(`/api/parts/restore-all`, {params: urlParams});

export const getBoxManage = boxId => axios.get(`/api/bins/manage`, {params: {id: boxId}});
export const getPartsByBinIds = boxIds => axios.get(`/api/parts/box-ids`, {params: {boxIds: boxIds.join(',')}});
export const getPartsFromAllBins = base64 => axios.get('api/parts/boxes', {params: {base64: base64}});
export const getPartsByProductId = productIds => axios.post('/api/lists/parts/for-list/by-product-ids', productIds);

export const GenerateTitle = id => {
	return axios.get(`/api/parts/${id}/title?limit=true`, {validateStatus: status => status === 200});
};

export const GetMpnCodeMatchCount = code => axios.get(`/api/parts/mpn/${encodeURIComponent(code)}/count`);
export const GetMultipleMpbCodeMatchCount = codes =>
	axios.get(' /api/parts/mpn-count', {
		params: {mpnCodes: codes},
		paramsSerializer: params => qs.stringify(params),
	});

export const getPartsCsv = base64 => axios.get('/api/parts/partsCsv', {params: {base64: base64}});
export const getBinCsv = base64 => axios.get('/api/parts/binCsv', {params: {base64: base64}});
export const getListCsv = base64 => axios.get('/api/parts/listCsv', {params: {base64: base64}});

export const getPartsPdf = base64 => axios.get('/api/parts/pdf', {params: {base64: base64}});
export const getAllPartsPdf = base64 => axios.get('/api/parts/all-pdf', {params: {base64: base64}});
export const getPartsDismantlePdf = base64 => axios.get('/api/parts/dismantle-pdf', {params: {base64: base64}});
export const getAllPartsDismantlePdf = base64 => axios.get('/api/parts/all-dismantle-pdf', {params: {base64: base64}});
export const getBinsPdf = base64 => axios.get('/api/parts/boxes/pdf', {params: {base64: base64}});
export const getAllBinsPdf = base64 => axios.get('/api/parts/boxes/all-pdf', {params: {base64: base64}});

export const GetAllCategoriesByExactCategoryId = exactCategoryId =>
	axios.get(`/api/parts/exact-category/${exactCategoryId}/all-categories`);
export const GetAllCategoriesLists = category => axios.get('/api/parts/all-categories-lists', {params: category});
export const GetSuggestedCategories = mpn => axios.get('/api/parts/exact-category/suggestion', {params: {mpn: mpn}});
export const GetSimilarParts = (mpn, partId) =>
	axios.get('/api/parts/similar-parts', {params: {mpn: mpn, partId: partId}});

export const GetListIdByPartId = id => axios.get(`/api/parts/${id}/list-id`);

export const UpdatePartDialogData = (id, mainPartData) => axios.patch(`/api/parts/${id}/dialog`, mainPartData);

export const putPartsPhotos = partsWithPhotos => axios.put(`/api/parts/photos/upsert`, partsWithPhotos);

export const getPartIdsByMpnMatch = mpnCode => axios.get(`/api/parts/mpn/${mpnCode}/part-ids`);

export const updatePartSpecifics = (partId, mainPartData) => axios.patch(`api/parts/${partId}/specifics`, mainPartData);

export const addPartsToCart = partIds => axios.post('api/parts/cart', partIds);
export const deleteParts = partIds => axios.delete(`api/parts`, {data: partIds});
export const deletePartFromUserCart = id => axios.delete('api/parts/cart', {params: {id: id}});
export const getUserCartParts = () => axios.get('api/parts/cart');
export const getPartsByProductIdsForManage = productIds =>
	axios.get(`api${partManageByProductIdUrl}${productIds.join(',')}`);
export const partManageByProductIdUrl = '/parts/manage/by-product-ids?productIds=';
export const getCompatibilityOptions = partId => axios.get(`api/parts/compatibility-options/${partId}`);
export const getKTypes = request => axios.post('api/parts/k-types', request);
export const getPublicParts = (base64, cancelToken) =>
	axios.get('/api/public/part/list', {params: {base64}, cancelToken: cancelToken});
export const getAutomatedCategories = () => axios.get('api/parts/automated-categories');
export const copyPartData = (from, to, copyVehicleData) =>
	axios.post('api/parts/copy-part-data', {copyFromPartId: from, copyToPartId: to, copyVehicle: copyVehicleData});
export const getNextAndPrevParts = id => axios.get('/api/parts/nearest-ids', {params: {id}});
export const getCategoryTranslation = (categoryId, languageCode) =>
	axios.get('/api/shops/rrr/category-translation', {params: {categoryId, languageCode}});
