import {createTheme as createMuiTheme} from '@material-ui/core';
import _ from 'lodash';

const baseOptions = {
	direction: 'ltr',
	overrides: {
		MuiLinearProgress: {
			root: {
				borderRadius: 3,
				overflow: 'hidden',
			},
		},
		MuiListItemIcon: {
			root: {
				minWidth: 32,
			},
		},
		MuiChip: {
			root: {
				backgroundColor: 'rgba(0,0,0,0.075)',
			},
		},
		MuiIconButton: {
			root: {
				color: '#33bba4',
			},
		},
		MuiFormLabel: {
			root: {
				fontSize: '13px',
				fontWeight: 300,
				lineHeight: 'initial',
			},
		},
		MuiButton: {
			root: {
				'&:hover': {
					backgroundColor: '#48ecd1 !important',
				},
			},
		},
		MuiBadge: {
			badge: {
				padding: 0,
				fontSize: 9,
				fontWeight: 300,
				height: 15,
				width: 15,
				minWidth: 15,
			},
		},
		MuiFab: {
			root: {
				position: 'fixed',
				bottom: '16px',
				right: '16px',
				color: '#132347',
				backgroundColor: 'white',
				zIndex: 1000,
				'&:hover': {
					backgroundColor: '#48ecd1 !important',
				},
			},
		},
		MuiSelect: {
			select: {
				'&:focus': {
					backgroundColor: 'white',
				},
			},
		},
	},
};

const themeOptions = {
	palette: {
		type: 'light',
		action: {
			active: '#33bba4',
			hover: '#f4f6f8',
			selected: '#f4f6f8',
			disabled: 'rgba(0, 0, 0, 0.06)',
			disabledBackground: 'rgba(255, 255, 255, 0.12)',
			focus: 'rgba(255, 255, 255, 0.12)',
		},
		background: {
			default: '#f4f6f8',
			dark: '#132347',
			pink: '#feecef',
			message: '#00000013',
			messageInputEditMode: '#d9d9d9',
		},
		primary: {
			main: '#33bba4',
			text: '#677275',
			hover: '#48ecd1',
			outline: '#e0e0e0',
		},
		buttons: {
			threeDots: 'black',
			lightBackground: 'white',
		},
		secondary: {
			main: '#f4f6f8',
			gray: '#ededed',
		},
		text: {
			primary: '#132347',
			secondary: '#c4c4c4',
		},
		error: {
			main: '#d42b2b',
		},
		success: {
			main: '#49cc85',
		},
		textWeight: {
			light: 300,
			medium: 500,
		},
		icons: {
			gray: '#717b7e',
			viberPurple: '#7b519d',
			whatsappGreen: '#00e676',
			facebookBlue: '#3a559f',
		},
	},
};
export const createTheme = () => {
	let theme = createMuiTheme(
		{
			props: {
				MuiTypography: {
					variantMapping: {
						subtitle1: 'p',
						subtitle2: 'p',
					},
				},
			},
			typography: {
				fontFamily: 'Roboto',
			},
			overrides: {
				MuiTypography: {
					h6: {
						color: '#677275',
						paddingLeft: '13px',
						fontSize: '19px',
					},
					h5: {
						color: '#677275',
						fontWeight: '500',
						fontSize: '17px',
					},
					body1: {
						color: '#132347',
						fontWeight: '500',
						fontSize: '16px',
					},
					body2: {
						color: '#677275',
						fontWeight: '500',
						fontSize: '13px',
					},
					subtitle1: {
						color: '#677275',
						fontWeight: '500',
						fontSize: '13px',
						lineHeight: 'initial',
						component: 'p',
					},
					subtitle2: {
						color: '#677275',
						fontWeight: 'lighter',
						fontSize: '11px',
						lineHeight: 'initial',
					},
					caption: {
						color: '#677275',
						fontSize: '13px',
						lineHeight: 'initial',
					},
				},
				MuiTable: {
					root: {
						tableLayout: 'fixed',
					},
				},
				MuiTableCell: {
					root: {
						padding: '16px 8px',
						wordBreak: 'break-all',
					},
					head: {
						color: '#677275',
						paddingBottom: '5px',
						fontSize: '13px',
					},
				},
				MuiTab: {
					textColorPrimary: {
						color: '#677275',
					},
				},
				MuiCheckbox: {
					root: {
						color: '#132347',
					},
					colorSecondary: {
						'&$checked': {
							color: '#132347',
						},
					},
				},
				MuiSelect: {
					icon: {
						color: '#33bba4',
					},
				},
				MuiListItem: {
					root: {
						'&$selected': {
							background: '#f4f6f8',
						},
					},
				},
				PrivateNotchedOutline: {
					legendLabelled: {
						fontSize: '9.5px',
					},
				},
				MuiInputLabel: {
					root: {
						'&$disabled': {
							color: 'rgba(0, 0, 0, 0.1)',
						},
					},
				},
				MuiDialog: {
					paperWidthMd: {
						maxWidth: '784px',
					},
				},
			},
		},
		_.merge({}, baseOptions, themeOptions)
	);

	return theme;
};

export default createTheme;
