import axios from './authRequest';
import qs from 'qs';
import {shipmentStatuses} from '../constants';

export const GetOrdersStatus = () => axios.get('/api/orders/dropdowns/status');
export const postOrder = body => axios.post('/api/orders/manual', body);
export const returnOrder = body => axios.post('/api/orders/back-to-active', body);
export const editOrder = body => axios.put('/api/orders/manual', body);
export const getAllPaymentMethods = () => axios.get('/api/orders/payment-methods');

export const getReasonNotFound = base64 => axios.get('/api/orders/packing-orders/reason-not-found', {params: {base64: base64}});

export const getOrdersPdf = base64 => axios.get('/api/orders/pdf', {params: {base64: base64}});
export const getAllOrdersPdf = base64 => axios.get('/api/orders/all-pdf', {params: {base64: base64}});
export const getInvoicePdf = base64 => axios.get('/api/orders/invoice-pdf', {params: {base64: base64}});
export const getCourierForms = base64 => axios.get('/api/orders/courier-forms', {params: {base64: base64}});
export const getShipmentTracking = (shipmentId, courierServiceId) =>
	axios.get('/api/post-services/tracking', {params: {shipmentId, courierServiceId}});
export const getOrderCsv = base64 => axios.get('/api/orders/orderCsv', {params: {base64: base64}});
export const cancelShipmentInStore = id => axios.put(`/api/shops/shipment/${id}/cancel`);

export const UpdateOrdersStatus = newStatus => axios.put('/api/orders/status', newStatus);
export const setOrderStatusToWeighing = shipmentId => UpdateOrdersStatus({ids: [shipmentId], statusId: shipmentStatuses.weighing.id});
export const setOrderStatusToWeighed = shipmentId => UpdateOrdersStatus({ids: [shipmentId], statusId: shipmentStatuses.weighed.id});
export const setOrderStatusToPacked = shipmentId => UpdateOrdersStatus({ids: [shipmentId], statusId: shipmentStatuses.packed.id});
export const setOrderStatusToPicked = shipmentId => UpdateOrdersStatus({ids: [shipmentId], statusId: shipmentStatuses.picked.id});
export const setOrderStatusToPacking = shipmentId => UpdateOrdersStatus({ids: [shipmentId], statusId: shipmentStatuses.packaging.id});
export const setOrderStatusToWithIssues = shipmentId => UpdateOrdersStatus({ids: [shipmentId], statusId: shipmentStatuses.withIssues.id});
export const setOrderStatusToBroken = shipmentId => UpdateOrdersStatus({ids: [shipmentId], statusId: shipmentStatuses.broken.id});
export const setOrderStatusToReadyForPickUp = shipmentId => UpdateOrdersStatus({ids: [shipmentId], statusId: shipmentStatuses.readyForPickUp.id});

export const updateShipmentDimensions = shipment => axios.put('/api/orders/dimensions', null, {params: shipment});
export const updateShipmentWeight = shipment => axios.put('/api/orders/weight', null, {params: shipment});
export const getFullOrderData = orderId => axios.get(`/api/orders/full-order-data/${orderId}`);
export const getOrderDeclaredTitle = orderId => axios.get(`/api/orders/declared-title/${orderId}`);
export const getMultipleFullOrderData = orderIds =>
	axios.get(`/api/orders/multiple-full-order-data`, {params: {ids: orderIds.join(',')}});
export const getFullOrderDataWithoutSameShipment = orderId =>
	axios.get(`/api/orders/full-order-data/manage/${orderId}`);
export const checkout = () => axios.post('/api/orders/checkout');
export const checkVatNumber = (countryCode, vatCode) =>
	axios.get('/api/orders/checkVatCode', {params: {countryCode, vatCode}});
export const confirmPickedUpFromAnotherWarehouse = shipmentId =>
	axios.post(`/api/orders/confirm-picked-up-from-another-warehouse/${shipmentId}`);
export const updateOrderPackedStatus = (shipmentId, isPacked) =>
	axios.put('/api/orders/package-status', null, {params: {shipmentId: shipmentId, isPacked: isPacked}});
export const updateShipmentNote = (shipmentId, note) =>
	axios.put(`/api/orders/shipments/${shipmentId}/note`, null, {params: {shipmentId: shipmentId, note: note}});
export const getCompanyData = () => axios.get('/api/orders/companies');
export const getNextManualInvoiceNumber = invoiceTypeId =>
	axios.get(`/api/orders/get-next-manual-invoice-number/${invoiceTypeId}`);
export const validateNotUsedInvoiceNumber = (invoiceNumber, invoiceTypeId, orderId) =>
	axios.get(`/api/orders/validate-not-used-manual-invoice-number/${invoiceNumber}/${invoiceTypeId}/${orderId}`);
export const getPickupFromAnotherWarehouseProducts = shipmentId =>
	axios.get(`/api/orders/get-pickup-from-another-warehouse-products/${shipmentId}`);
export const getMarketplaceAccountTotals = base64 =>
	axios.get(`/api/orders/marketplace-account-totals?base64=${base64}`);
export const getPostagePrices = (country, city, postCode, dimensions, totalWeight, cancelToken) =>
	axios.get('/api/post-services/postage-prices', {
		params: {
			shipment: {
				postCode,
				dimensions,
				totalWeight,
			},
			country: {
				name: country,
			},
			city: {name: city},
		},
		paramsSerializer: params => qs.stringify(params, {allowDots: true}),
		cancelToken: cancelToken,
	});

export const updateOrderItemStatus = orderProductStatusUpdate =>
	axios.patch('/api/orders/items/status', orderProductStatusUpdate);

export const getOrderItemStatuses = shipmentId => axios.get(`/api/orders/items/status/${shipmentId}`);
