import moment from 'moment';
import {
    getPartsPdf,
    getBinsPdf,
    getAllBinsPdf,
    getAllPartsPdf,
    getAllPartsDismantlePdf,
    getPartsDismantlePdf,
} from '../api/Part';
import {
    getVehiclesPdf,
    getAllVehiclesPdf,
    getAllCollectionsPdf
} from '../api/Vehicle';
import {getListsPdf, getAllListsPdf} from '../api/List';
import {
    getOrdersPdf,
    getAllOrdersPdf,
    getCourierForms,
    getInvoicePdf
} from '../api/Order';
import {useCustomSnackbar} from './useCustomSnackbar';
import {toBase64Json} from '../helpers/global/functions/convert';
import {GetMessages, GetChatTypes} from '../api/chat';
import {getVehicleByProductId} from '../api/Vehicle';
import 'jspdf-autotable';
import {jsPDF} from '../assets/fonts/upgradedJsPdf';

/**
 *
 * @param {number} status
 * @returns boolean
 */
function isAxiosSuccess(status) {
    return status && status.toString().startsWith(2);
}

function base64ToPdfBlob(base64) {
    const byteCharacters = atob(base64);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
        const slice = byteCharacters.slice(offset, offset + 512);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, {type: 'application/pdf'});
    return blob;
}

/**
 * Function to download file.
 *
 * @param {object} file Blob pdf file.
 * @param {string} fileName File name.
 */
const downloadFile = (file, fileName) => {
    if (window.navigator.msSaveOrOpenBlob) {
        // IE10+
        window.navigator.msSaveOrOpenBlob(file, fileName);
    } else {
        // Others
        const a = document.createElement('a'),
            url = URL.createObjectURL(file);
        a.href = url;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();

        setTimeout(() => {
            document.body.removeChild(a);
            window.URL.revokeObjectURL(url);
        }, 0);
    }
};

/**
 * Generate items pdf.
 *
 * @param {function} getItemsPdf Function to get items pdf.
 * @param {number[]} itemIds Item ids array.
 * @param {object} p Polyglot.
 * @param {string} fileName File name.
 * @param showError
 * @param courierServiceId
 * @param itemsPdfParams
 * @param disableState
 * @param print
 */
const generatePdf = async (
    getItemsPdf,
    itemIds,
    fileName,
    showError,
    p = null,
    courierServiceId = null,
    itemsPdfParams = null,
    disableState = null,
    print = null
) => {
    try {
        disableState && disableState(true);
        const base64 = toBase64Json({
            ...itemsPdfParams,
            dateTime: moment().format('YYYY-MM-DDTHH:mm:ss'),
            ids: itemIds,
            courierServiceId: courierServiceId,
        });
        const response = await getItemsPdf(base64);
        if (print) {
            await printFile(response, print, showError, p);
        } else {
            await generateFile(p, fileName, response, showError);
        }
        disableState && disableState(false);
    } catch (error) {
        showError(error.response.data.title);
        disableState && disableState(false);

    }
};

/**
 * Generate all items pdf.
 *
 * @param {function} getItemsPdf Function to get items pdf.
 * @param {object} p Polyglot.
 * @param {string} fileName File name.
 * @param showError
 * @param searchProperties
 * @param itemsPdfParams
 * @param disableState
 * @param print
 */
const generateAllItemsPdf = async (
    getItemsPdf,
    fileName,
    showError,
    searchProperties,
    p = null,
    itemsPdfParams = null,
    disableState = null,
    print = null
) => {
    try {
        disableState && disableState(true);
        const base64 = toBase64Json({
            ...itemsPdfParams,
            filters: searchProperties,
            dateTime: moment().format('YYYY-MM-DDTHH:mm:ss'),
        });
        const response = await getItemsPdf(base64);
        if (print) {
            await printFile(response, print, showError, p);
        } else {
            await generateFile(p, fileName, response, showError);
        }
        disableState && disableState(false);
    } catch (error) {
        showError(error.message);
        disableState && disableState(false);

    }
};

const printFile = async (response, print, showError, p) => {
    if (!isAxiosSuccess(response?.status)) {
        showError(response.toString());
        return;
    }
    if (!response.data) {
        showError(p.tc('failed to retrieve pdf'));
        return;
    }
    const base64 = response.data;
    if (base64) {
        await print(base64);
    }
};

/**
 * Generate file.
 *
 * @param {function} getItemsPdf Function to get items pdf.
 * @param {object} p Polyglot.
 * @param {string} fileName File name.
 */
const generateFile = async (p, fileName, response, showError) => {
    if (!isAxiosSuccess(response?.status)) {
        showError(response.toString());
        return;
    }
    if (!response.data) {
        showError(p.tc('failed to retrieve pdf'));
        return;
    }
    const blobPdf = base64ToPdfBlob(response.data);
    if (blobPdf) {
        const url = URL.createObjectURL(blobPdf);
        window.open(
            url,
            'sample',
            `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=${window.screen.width},height=${window.screen.height},left=0,top=0`
        );
        downloadFile(blobPdf, `${p ? p.t(fileName) : fileName}_${moment().format('YYYY-MM-DD_HH-mm-ss')}.pdf`);
    }
};

export function usePdfGenerator(disableState = null) {
    const {showError, showResponseError} = useCustomSnackbar();

    const generateInvoicePdf = orderIds => {
        generatePdf(getInvoicePdf, orderIds, 'Daviva_Saskaita(Invoice)', showError, null, null, null, disableState);
    };

    const generateCourierForms = (orderIds, courierServiceId) => {
        generatePdf(getCourierForms, orderIds, 'Courier_Forms', showError, null, courierServiceId, null, disableState);
    };

    /**
     * Generate parts pdf.
     *
     * @param {number[]} partIds Part ids array.
     * @param {object} p Polyglot.
     */
    const generatePartsPdf = (partIds, p) => {
        generatePdf(getPartsPdf, partIds, 'parts', showError, p, null, null, disableState);
    };

    /**
     * Generate all parts pdf.
     *
     * @param {object} p Polyglot.
     */
    const generateAllPartsPdf = (p, searchProperties) => {
        generateAllItemsPdf(getAllPartsPdf, 'parts', showError, searchProperties, p, null, disableState);
    };

    /**
     * Generate parts pdf.
     *
     * @param {number[]} partIds Part ids array.
     * @param {object} p Polyglot.
     */
    const generatePartsDismantlePdf = (partIds, p) => {
        generatePdf(getPartsDismantlePdf, partIds, 'parts', showError, p, null, null, disableState);
    };

    /**
     * Generate all parts pdf.
     *
     * @param {object} p Polyglot.
     */
    const generateAllPartsDismantlePdf = (p, searchProperties) => {
        generateAllItemsPdf(getAllPartsDismantlePdf, 'parts', showError, searchProperties, p, null, disableState);
    };

    /**
     * Generate vehicles pdf.
     *
     * @param {number[]} vehicleIds Vehicle ids array.
     * @param {object} p Polyglot.
     */
    const generateVehiclesPdf = (vehicleIds, p) => {
        generatePdf(getVehiclesPdf, vehicleIds, 'vehicles', showError, p, null, null, disableState);
    };

    /**
     * Generate all vehicles pdf.
     *
     * @param {object} p Polyglot.
     */
    const generateAllVehiclesPdf = (p, searchProperties, isVehicleAsCollection = false) => {
        generateAllItemsPdf(
            isVehicleAsCollection ? getAllCollectionsPdf : getAllVehiclesPdf,
            isVehicleAsCollection ? 'collections' : 'vehicles',
            showError,
            searchProperties,
            p,
            null,
            disableState,
            isVehicleAsCollection
        );
    };

    /**
     * Generate lists parts pdf.
     *
     * @param {number[]} listIds List ids array.
     * @param {object} p Polyglot.
     */
    const generateListsPdf = (listIds, p) => {
        generatePdf(getListsPdf, listIds, 'parts', showError, p, null, null, disableState);
    };

    /**
     * Generate all lists parts pdf.
     *
     * @param {object} p Polyglot.
     */
    const generateAllListsPdf = (p, searchProperties) => {
        generateAllItemsPdf(getAllListsPdf, 'parts', showError, searchProperties, p, null, disableState);
    };

    /**
     * Generate bins parts pdf.
     *
     * @param {number[]} binIds Bin ids array.
     * @param {object} p Polyglot.
     */
    const generateBinsPdf = (binIds, p) => {
        generatePdf(getBinsPdf, binIds, 'parts', showError, p, null, null, disableState);
    };

    /**
     * Generate all bins parts pdf.
     *
     * @param {object} p Polyglot.
     */
    const generateAllBinsPdf = (p, searchProperties) => {
        generateAllItemsPdf(getAllBinsPdf, 'parts', showError, searchProperties, p, null, disableState);
    };

    /**
     * Generate orders pdf.
     *
     * @param {number[]} shippingIds Orders ids array.
     * @param {object} p Polyglot.
     */
    const generateOrdersPdf = (shippingIds, p, combined = false) => {
        generatePdf(getOrdersPdf, shippingIds, 'orders', showError, p, null, {combined}, disableState);
    };

    /**
     * Generate all orders pdf.
     *
     * @param {object} p Polyglot.
     */
    const generateAllOrdersPdf = (p, searchProperties, combined = false, print = null) => {
        generateAllItemsPdf(getAllOrdersPdf, 'orders', showError, searchProperties, p, {combined}, disableState, print);
    };

    /**
     * Open pdf from base64.
     *
     * @param {string} base64 Base64.
     */
    const generatePostageLabel = async (base64, print = null) => {
        if (print) {
            await print(base64);
            return;
        }

        const blobPdf = base64ToPdfBlob(base64);

        if (blobPdf) {
            const url = URL.createObjectURL(blobPdf);
            const screenWidthCenter = window.screen.width / 2 - 300;
            const screenHeightCenter = window.screen.height / 2 - 400;
            window.open(
                url,
                'sample',
                `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=600,height=800,left=${screenWidthCenter},top=${screenHeightCenter}`
            );
            // windowPdf.print();
        }
    };

    const generateChatMessagesPdf = async (productId, p) => {
        try {
            // Get data from API.
            const messages = (await GetMessages(productId)).data;
            const types = (await GetChatTypes()).data;
            const vehicle = (await getVehicleByProductId(productId)).data;
            // Setup styles.
            const doc = new jsPDF();
            doc.setFontSize(8);
            // Add header table containing date and vehicle title.
            doc.autoTable({
                body: [
                    [
                        {
                            content: `${p.tc('printed on')}: ${moment(new Date()).format('YYYY-MM-DD HH:mm')}`,
                            styles: {fontSize: 8, halign: 'center'},
                        },
                    ],
                    [
                        {
                            content: `${vehicle.id} | ${vehicle.manufactureYear} ${vehicle.kType.make} ${vehicle.kType.model} ${vehicle.kType.type}`,
                            styles: {fontSize: 12, halign: 'center'},
                        },
                    ],
                ],
                theme: 'plain',
            });
            const columnStyles = {
                0: {cellWidth: 'auto', overflow: 'linebreak', font: 'Roboto'},
                1: {
                    cellWidth: 8,
                    overflow: 'linebreak',
                    fontSize: 6,
                    font: 'Roboto'
                },
                2: {
                    cellWidth: 22,
                    overflow: 'linebreak',
                    fontSize: 6,
                    font: 'Roboto'
                },
            };
            const styles = {
                halign: 'left',
                valign: 'middle',
            };
            const headStyles = {
                fillColor: 'white',
                fontStyle: 'bold',
                textColor: 'black',
                font: 'Roboto',
            };
            // Build rows.
            types.forEach(t => {
                const msg = messages
                    .filter(m => m.comment.productCommentTypeId === t.id)
                    .map(m => [
                        m.comment.message,
                        m.account.firstName[0] + m.account.lastName[0],
                        moment(m.comment.createdDate).format('YYYY-MM-DD HH:mm'),
                    ]);
                const head = [
                    [
                        {
                            content: p.tu(t.name.toLowerCase()),
                            colSpan: 3,
                            styles: {
                                fontSize: 14,
                                fontWeight: 'bold',
                                fillColor: 'white',
                                font: 'Roboto'
                            },
                        },
                    ],
                ];
                msg.length > 0 &&
                doc.autoTable({
                    head: head,
                    body: msg,
                    columnStyles: columnStyles,
                    headStyles: headStyles,
                    showHead: 'everyPage',
                    theme: 'grid',
                    styles: styles,
                });
            });
            // Document title (name).
            doc.setProperties({title: 'messages'});
            // Create a pdf blob.
            const blob = doc.output('blob');
            const url = URL.createObjectURL(blob);
            // Open url in a new window
            window.open(
                url,
                'sample',
                `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=600,height=800`
            );
        } catch (e) {
            // If something goes wrong shows error and exits.
            showResponseError(e);

        }
    };

    return {
        generateInvoicePdf,
        generatePartsPdf,
        generateVehiclesPdf,
        generateListsPdf,
        generateBinsPdf,
        generateOrdersPdf,
        generateAllVehiclesPdf,
        generateAllPartsPdf,
        generateAllOrdersPdf,
        generateAllListsPdf,
        generateAllBinsPdf,
        generateCourierForms,
        generatePostageLabel,
        generateChatMessagesPdf,
        generatePartsDismantlePdf,
        generateAllPartsDismantlePdf,
    };
}
